import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const EnafContainer = styled.div`
  width: 100vw;
  max-width: 1170px;
  margin: auto;
  font-family: 'Roboto', sans-serif, ariel;
  font-size: 10px;
`;

const HeadlineContainer = styled.div`
  box-sizing: border-box;
  background: #00577d;
  text-transform: uppercase;
  padding: 10px;
  margin: 20px auto;
  max-width: 980px;
  text-align: center;
  h1 {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    color: #fff;
    @media (max-width: 801px) {
      font-size: 15px;
    }
  }
  .bold {
    font-size: 44px;
    font-weight: 500;
    @media (max-width: 801px) {
      font-size: 26px;
    }
  }
`;

const LogoContainer = styled.div`
  width: calc(100% - 20px);
  margin: 20px auto;
  max-width: 275px;
  @media (max-width: 801px) {
    max-width: 165px;
  }
  img {
    max-width: 100%;
    margin-bottom: 5px;
  }
`;

const QuestionsContainer = styled.div`
  box-sizing: border-box;
  background: #dee8ef;
  border: none;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  max-width: 980px;
  color: #333;
  padding: 10px 20px 10px;
  margin: auto;
  .questionsHeadline {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
  }
`;

const ThreeItems = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 801px) {
    flex-wrap: wrap;
  }
`;
const ThreeItemsContent = styled.div`
  width: 31%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  color: #333;
  img {
    width: 65px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
  }
  @media (max-width: 801px) {
    width: 100%;
  }
`;

const DividerContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, #7fa9be 0%, #93c7e3 100%);
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
  h2 {
    text-shadow: 0 3px 6px #333;
    margin: 0;
    text-align: center;
    padding: 0 0 5px;
    line-height: 1.2;
    font-size: calc(16px + 0.7vw);
    font-weight: 400;
  }
  img {
    max-width: 100%;
    margin-bottom: 0;
  }
`;

const QuestionList = styled.div`
  ul {
    column-count: 2;
    column-gap: 20px;
    font-size: 13px;
    font-weight: 600;
    padding-left: 5px;
    color: #333;
    @media (max-width: 801px) {
      column-count: 1;
    }
  }
  li {
    list-style-type: none;
    margin-bottom: 10px;
    background: url('https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooDisc.png')
      0 4px/12px no-repeat;
    padding-left: 20px;
  }
`;

const EnafThankyou = () => (
  <EnafContainer>
    <HeadlineContainer>
      <h1>
        <span className="bold"> You've been matched</span>
        <br />
        with our featured provider, New American Funding
      </h1>
    </HeadlineContainer>
    <LogoContainer>
      <img
        src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/nafLogo.jpg"
        alt="JFQ Lending"
      />
    </LogoContainer>
    <QuestionsContainer>
      <p className="questionsHeadline">
        Let us guide you through the process, so you can join the thousands of
        happy homeowners who trust New American Funding. We've simplified the
        process and created tools to give you a hassle-free experience with your
        mortgage. Call <a href="tel:877-312-6485">877-312-6485</a>
      </p>
      <ThreeItems>
        <ThreeItemsContent>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooHomeIcon.png"
            alt="Home Icon"
          />
          <p>
            Historically Low Rates - See Today's Interest Rates Pay Off Higher
            Interest Rates Credit Cards, Pay College Tuition.
          </p>
        </ThreeItemsContent>
        <ThreeItemsContent>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooInstitutionIcon.png"
            alt="Institution Icon"
          />
          <p>Over 160k+ Positive Reviews, A+ Rating With BBB.</p>
        </ThreeItemsContent>
        <ThreeItemsContent>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooMoneyIcon.png"
            alt="Money Icon"
          />
          <p>
            Cash Out, Consolidation Options Available Offers Flexible Terms from
            8 to 30 Years.
          </p>
        </ThreeItemsContent>
      </ThreeItems>
      <DividerContainer>
        <h2>Have questions? New American Funding can help!</h2>

        <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooQuestionIcon.png"
          alt="Question Icon"
        />
      </DividerContainer>
      <QuestionList>
        <ul>
          <li>Can I lower my monthly mortgage payment?</li>
          <li>Will I close within 30 days?</li>
          <li>
            Will I receive important updates and communications from my lender?
          </li>
          <li>Can I complete the application process online?</li>
          <li>Is now a good time for me to switch to a 15-year fixed?</li>
        </ul>
      </QuestionList>
    </QuestionsContainer>
  </EnafContainer>
);

const googleFontLink =
  'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900';

export default withThankyouWrapper(EnafThankyou, googleFontLink);
